define('ember-rollbar-client/instance-initializers/rollbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    var rollbarService = appInstance.lookup('service:rollbar');

    Ember.onerror = function (error) {
      return rollbarService.error(error);
    };
    window.onerror = function (error) {
      return rollbarService.error(error);
    };
    Ember.RSVP.on('error', function (error) {
      return rollbarService.error(error);
    });
    Ember.$(document).ajaxError(function (data, xhr) {
      return rollbarService.error(xhr.statusText, data);
    });
  }

  exports.default = {
    name: 'rollbar',
    initialize: initialize
  };
});