define('ember-cli-html5-validation/components/form-control', ['exports', 'ember', 'ember-cli-html5-validation/templates/components/form-control'], function (exports, _ember, _emberCliHtml5ValidationTemplatesComponentsFormControl) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCliHtml5ValidationTemplatesComponentsFormControl['default'],

    /**
     * @param {Array}
     */
    classNames: ['form__control'],

    /**
     * @param {Array}
     */
    classNameBindings: ['customErrorClass'],

    /**
     * Error message from the underlying input
     *
     * @param {string}
     */
    error: null,

    /**
     * Class that is added to the wrapper element when there is an error
     *
     * @param {string}
     */
    errorClass: 'form__control--error',

    /**
     * @param {string}
     */
    customErrorClass: _ember['default'].computed('hasError', function () {
      return this.get('hasError') ? this.get('errorClass') : '';
    }),

    /**
     * @param {boolean}
     */
    hasError: _ember['default'].computed.bool('error'),

    actions: {
      /**
       * @param {string} inputName
       * @param {string} errorMessage
       * @returns {boolean}
       */
      updateErrorMessage: function updateErrorMessage(inputName, errorMessage) {
        this.set('error', errorMessage);
        this.parentView.send('updateErrorMessage', inputName, errorMessage);
      }
    }
  });
});