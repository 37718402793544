define('shared-goodcity/routes/messages_base', ['exports', 'goodcity/routes/authorize'], function (exports, _goodcityRoutesAuthorize) {
  exports['default'] = _goodcityRoutesAuthorize['default'].extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('isActive', true);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('isActive', false);
      }
    }
  });
});