define('smoke-and-mirrors/utils/key-for-item', ['exports', 'ember', 'smoke-and-mirrors/utils/identity'], function (exports, _ember, _smokeAndMirrorsUtilsIdentity) {
  exports['default'] = keyForItem;
  var get = _ember['default'].get;

  function keyForItem(item, keyPath, index) {
    var key = undefined;

    switch (keyPath) {
      case '@index':
        if (!index) {
          throw new Error('No index was supplied to keyForItem');
        }
        key = index;
        break;
      case '@identity':
        key = (0, _smokeAndMirrorsUtilsIdentity['default'])(item);
        break;
      default:
        if (keyPath) {
          key = get(item, keyPath);
        } else {
          key = (0, _smokeAndMirrorsUtilsIdentity['default'])(item);
        }
    }

    if (typeof key === 'number') {
      key = String(key);
    }

    return key;
  }
});
/* global String */