define('smoke-and-mirrors/models/list-satellite', ['exports', 'smoke-and-mirrors/models/satellite'], function (exports, _smokeAndMirrorsModelsSatellite) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  var _get = function get(_x, _x2, _x3) { var _again = true; _function: while (_again) { var object = _x, property = _x2, receiver = _x3; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x = parent; _x2 = property; _x3 = receiver; _again = true; desc = parent = undefined; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

  var ListSatellite = (function (_Satellite) {
    _inherits(ListSatellite, _Satellite);

    function ListSatellite(component, list) {
      _classCallCheck(this, ListSatellite);

      _get(Object.getPrototypeOf(ListSatellite.prototype), 'constructor', this).call(this, component);
      this.list = list;
    }

    _createClass(ListSatellite, [{
      key: 'heightDidChange',
      value: function heightDidChange(dY) {
        this.list._adjust(this, { dX: 0, dY: dY });
      }
    }, {
      key: 'next',
      value: function next() {
        var nextComponent = this.component.next();

        return nextComponent ? nextComponent.satellite : null;
      }
    }, {
      key: 'prev',
      value: function prev() {
        var prevComponent = this.component.prev();

        return prevComponent ? prevComponent.satellite : null;
      }
    }, {
      key: 'destroy',
      value: function destroy() {
        if (this.component.unregisterSatellite) {
          this.component.unregisterSatellite();
        }
        this.component = null;
        this.satellite = null;
        this.element = null;
        this.geography.destroy();
        this.geography = null;
        this.list = null;
      }
    }]);

    return ListSatellite;
  })(_smokeAndMirrorsModelsSatellite['default']);

  exports['default'] = ListSatellite;
});