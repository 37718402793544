define('smoke-and-mirrors/mixins/in-viewport', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var inject = _ember['default'].inject;
  exports['default'] = Mixin.create({
    inViewport: inject.service('in-viewport'),

    zoneX: 0,
    zoneY: 0,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('inViewport').register(this);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('inViewport').unregister(this);
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('inViewport').unregister(this);
    }

  });
});