define('ember-form-validator/components/validate-inputs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super();
      var elementArray = JSON.parse(this.get('elementCssIdentifiersArrayJson'));
      var errorClass = this.get('errorClass');
      var buttonId = this.get('buttonId');

      Ember.run.scheduleOnce('afterRender', this, function () {
        validateInputs();
        validateForm();
      });

      function validateInputs() {
        elementArray.forEach(function (selector) {
          Ember.$(selector).focusout(function () {
            return checkInput(this);
          });
        });

        elementArray.forEach(function (selector) {
          Ember.$(selector).focus(function () {
            return removeHighlight(this);
          });
        });
      }

      function checkInput(element) {
        var parent = Ember.$(element).parent();
        var value = Ember.$(element).val();

        if (value === undefined || value.length === 0 || value === "") {
          parent.addClass(errorClass);
        } else {
          parent.removeClass(errorClass);
        }
      }

      function removeHighlight(element) {
        var parent = Ember.$(element).parent();
        parent.removeClass(errorClass);
      }

      function validateForm() {
        Ember.$(buttonId).click(function () {
          elementArray.forEach(function (selector) {
            return checkInput(selector);
          });
          if (Ember.$(errorClass).length > 0) {
            return false;
          }
        });
      }
    }
  });
});