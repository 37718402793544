define('smoke-and-mirrors/models/satellite', ['exports', 'ember', 'smoke-and-mirrors/models/geography'], function (exports, _ember, _smokeAndMirrorsModelsGeography) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var guidFor = _ember['default'].guidFor;

  var Satellite = (function () {
    function Satellite(component, radar) {
      _classCallCheck(this, Satellite);

      this.radar = radar;
      this.component = component;
      this.element = component.element;
      this.geography = new _smokeAndMirrorsModelsGeography['default'](this.element);
      this.key = guidFor(component);
      if (component.registerSatellite) {
        component.registerSatellite(this);
      }
    }

    _createClass(Satellite, [{
      key: 'heightDidChange',
      value: function heightDidChange() /* delta */{}
    }, {
      key: 'widthDidChange',
      value: function widthDidChange() /* delta */{}
    }, {
      key: 'resize',
      value: function resize() {
        var cached = this.geography.getState();

        this.geography.setState();

        var heightChange = this.geography.height - cached.height;
        var widthChange = this.geography.width - cached.width;

        if (heightChange) {
          this.heightDidChange(-1 * heightChange);
        }
        if (widthChange) {
          this.widthDidChange(-1 * widthChange);
        }

        return heightChange || widthChange ? { dX: widthChange, dY: heightChange } : null;
      }
    }, {
      key: '_shift',
      value: function _shift(dY, dX) {
        this.geography.left -= dX;
        this.geography.right -= dX;
        this.geography.bottom -= dY;
        this.geography.top -= dY;
      }
    }, {
      key: 'willShift',
      value: function willShift() {}
    }, {
      key: 'didShift',
      value: function didShift() {}
    }, {
      key: 'shift',
      value: function shift(dY, dX) {
        this.willShift(dY, dX);
        this._shift(dY, dX);
        this.didShift(dY, dX);
      }
    }, {
      key: 'destroy',
      value: function destroy() {
        if (this.component.unregisterSatellite) {
          this.component.unregisterSatellite();
        }
        this.component = null;
        this.satellite = null;
        this.element = null;
        this.geography.destroy();
        this.geography = null;
        this.radar = null;
      }
    }]);

    return Satellite;
  })();

  exports['default'] = Satellite;
});