define('smoke-and-mirrors/utils/element/get-dimensions', ['exports', 'smoke-and-mirrors/utils/element/dim-from-str', 'smoke-and-mirrors/utils/element/get-width', 'smoke-and-mirrors/utils/element/get-height'], function (exports, _smokeAndMirrorsUtilsElementDimFromStr, _smokeAndMirrorsUtilsElementGetWidth, _smokeAndMirrorsUtilsElementGetHeight) {
  exports['default'] = getDimensions;

  function getDimensions(element) {
    var computedStyle = window.getComputedStyle(element, null);
    var style = {
      width: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.width),
      height: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.height),
      marginLeft: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.marginLeft),
      marginRight: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.marginRight),
      marginTop: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.marginTop),
      marginBottom: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.marginBottom),
      paddingLeft: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.paddingLeft),
      paddingRight: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.paddingRight),
      paddingTop: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.paddingTop),
      paddingBottom: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.paddingBottom),
      borderLeftWidth: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.borderLeftWidth),
      borderRightWidth: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.borderRightWidth),
      borderTopWidth: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.borderTopWidth),
      borderBottomWidth: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.borderBottomWidth),
      boxSizing: computedStyle.boxSizing,
      fontSize: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.fontSize),
      lineHeight: (0, _smokeAndMirrorsUtilsElementDimFromStr['default'])(computedStyle.lineHeight)
    };

    return {
      style: style,
      calc: {
        width: (0, _smokeAndMirrorsUtilsElementGetWidth['default'])(style),
        height: (0, _smokeAndMirrorsUtilsElementGetHeight['default'])(style),
        widthWithMargin: (0, _smokeAndMirrorsUtilsElementGetWidth['default'])(style, true),
        heightWithMargin: (0, _smokeAndMirrorsUtilsElementGetHeight['default'])(style, true)
      }
    };
  }
});