define('smoke-and-mirrors/debug/vertical-collection-debugging', ['exports', 'ember', 'smoke-and-mirrors/debug/visualization'], function (exports, _ember, _smokeAndMirrorsDebugVisualization) {
  var run = _ember['default'].run;
  var computed = _ember['default'].computed;
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    showEdges: false,
    _nextVisualization: null,

    toggleEdgeVisualization: function toggleEdgeVisualization() {
      this.toggleProperty('showEdges');
    },

    __smScheduleUpdate: function __smScheduleUpdate(source) {
      this._super(source);
      this.visualize();
    },

    visualization: null,
    setupHandlers: function setupHandlers() {
      this._super();
      if (this.get('showEdges')) {
        this.visualization = new _smokeAndMirrorsDebugVisualization['default'](this);
      }
    },

    _edges: computed('containerSize', function () {
      if (!this.radar || !this.radar.planet) {
        return {};
      }

      // segment top break points
      this.radar.planet.setState();

      var bufferSize = Number(this.get('bufferSize'));
      var rect = this.radar.planet;

      this.visualize();

      return {
        viewportTop: rect.top,
        visibleTop: -1 * bufferSize * rect.height + rect.top,
        viewportBottom: rect.bottom,
        visibleBottom: bufferSize * rect.height + rect.bottom
      };
    }),

    _removeComponents: function _removeComponents(toCull, toHide) {
      this._super(toCull, toHide);
      this.visualize();
    },

    visualize: function visualize() {
      var _this = this;

      if (!this.get('showEdges')) {
        if (this.visualization) {
          this.visualization.destroy();
          this.visualization = null;
        }
        return;
      }
      this._nextVisualization = run.scheduleOnce('afterRender', function () {
        if (_this.visualization) {
          _this.visualization.render();
        }
      });
    },

    __prependComponents: function __prependComponents(addCount) {
      this._super(addCount);
      this.visualize();
    },

    willDestroy: function willDestroy() {
      this._super();
      run.cancel(this._nextVisualization);
      if (this.visualization) {
        this.visualization.destroy();
        this.visualization = null;
      }
    }

  });
});