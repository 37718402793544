define("shared-goodcity/controllers/offer/transport_details", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    delivery: _ember["default"].computed.alias("model.delivery"),
    contact: _ember["default"].computed.alias("delivery.contact"),
    hasActiveGGVOrder: _ember["default"].computed.alias("delivery.gogovanOrder.isActive"),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    isDonorApp: _ember["default"].computed.alias("session.isDonorApp"),

    user: _ember["default"].computed(function () {
      var userId = this.get("model.createdBy.id") || this.session.get("currentUser.id");
      return this.store.peekRecord("user", userId);
    }).volatile(),

    userName: _ember["default"].computed("contact.name", "user.fullName", function () {
      return this.get("contact.name") || this.get("user.fullName");
    }),

    userMobile: _ember["default"].computed("contact.mobile", "user.mobile", function () {
      return this.get("contact.mobile") || this.get("user.mobile");
    }),

    district: _ember["default"].computed("contact.address.district.name", "user.address.district.name", function () {
      return this.get("contact.address.district.name") || this.get("user.address.district.name");
    }),

    actions: {
      handleBrokenImage: function handleBrokenImage() {
        this.get("model.reviewedBy").set("hasImage", null);
      },

      cancelDelivery: function cancelDelivery() {
        if (this.get("hasActiveGGVOrder")) {
          // this.set('cancelBooking', true);
          this.transitionToRoute("delivery.cancel_booking", this.get("delivery")).then(function (newRoute) {
            return newRoute.controller.set("isCancel", true);
          });
        } else {
          this.send("removeDelivery", this.get("delivery"));
        }
      },

      modifyBooking: function modifyBooking() {
        if (this.get("hasActiveGGVOrder")) {
          this.transitionToRoute("delivery.cancel_booking", this.get("delivery")).then(function (newRoute) {
            return newRoute.controller.set("isCancel", false);
          });
        } else {
          this.transitionToRoute("offer.plan_delivery", this.get("delivery.offer"), { queryParams: { modify: true } });
        }
      },
      /* jshint ignore:start */
      removeDelivery: function removeDelivery(delivery) {
        var _this = this;

        this.get("messageBox").custom(this.get("i18n").t("delete_confirm"), this.get("i18n").t("delivery.cancel.cancel_transport"), function () {
          var loadingView = _this.container.lookup("component:loading").append();
          var offer = delivery.get("offer");

          delivery.deleteRecord();
          delivery.save().then(function () {
            offer.set("state", "reviewed");
            var route = _this.get("session.isAdminApp") ? "review_offer" : "offer.offer_details";
            _this.transitionToRoute(route, offer);
          })["finally"](function () {
            return loadingView.destroy();
          });
        }, this.get("i18n").t("not_now"), null);
      }
      /* jshint ignore:end */
    }
  });
});