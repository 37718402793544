define("ember-infinite-list/components/infinite-list", ["exports", "lodash"], function (exports, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function isEmpty(results) {
    if (!results || results.length === 0) {
      return true;
    }
    if (_lodash.default.isFunction(results.get) && results.get("length") === 0) {
      return true; // Ember array
    }
    return false;
  }

  exports.default = Ember.Component.extend({
    height: "100vh",

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set("items", Ember.makeArray());
      this.set("pageNo", 1);
      this.set("isLoadingMore", false);
      this.set("isFinished", false);
      this.set("destroyed", false);
      this.fetchMoreRecords();

      this.listElement = Ember.$(this.element).find(".infinite-list-container");

      var _this = this;
      this.listElement.on("scroll", function () {
        _this.detectPosition(this);
      });
    },
    detectPosition: function detectPosition(elem) {
      if (this.get("isLoadingMore") || this.get("isFinished")) {
        return;
      }

      if ($(elem).scrollTop() + $(elem).innerHeight() >= $(elem)[0].scrollHeight - 100) {
        Ember.run.debounce(this, this.fetchMoreRecords, 100);
      }
    },
    _safeCb: function _safeCb(fn) {
      var _this2 = this;

      return function () {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        if (_this2.get("destroyed")) {
          return;
        }
        return fn.apply(_this2, args);
      };
    },
    fetchMoreRecords: function fetchMoreRecords() {
      var _this3 = this;

      this.set("isLoadingMore", true);

      Ember.RSVP.resolve(this.get("loadMore")(this.pageNo)).then(this._safeCb(function (newItems) {
        if (isEmpty(newItems)) {
          _this3.set("isFinished", true);
          return;
        }
        _this3.pageNo++;
        _this3.get("items").addObjects(newItems);
      })).finally(this._safeCb(function () {
        _this3.set("isLoadingMore", false);
      }));
    },
    willDestroyElement: function willDestroyElement() {
      this.set("destroyed", true);
      Ember.$(this.listElement).unbind();
    }
  });
});