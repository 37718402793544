define("shared-goodcity/locales/zh-tw/translations", ["exports"], function (exports) {
  var I18nTranslationsZhTw;

  I18nTranslationsZhTw = {
    "offline_error": "無法加載，請檢查網絡連線",
    "offline_page": "無法開啟應用程式，請檢查網絡連線",
    "try_again": "重試",
    "send": "傳送",
    "delete_confirm": "一旦刪除將無法還原，你確定嗎？",
    "online": "在線",
    "not_connected": "未連線",
    "not_now": "暫時不要",
    "crossroads": "十字路會",
    "language": "繁體中文",
    "loading": "正在加載...",
    "loading_timeout": "需時太久了！點擊重新加載",
    "goodcity.hk": "好人好市.HK",
    "skip_intro": "跳過簡介",
    "next": "下一步",
    "prev": "上一步",
    "back": "上一頁",
    "end": "結束",
    "done": "完成",
    "other": "其他",
    "error.message": "出錯了，請見諒！",
    "images": "圖片",
    "delete": "刪除",
    "edit": "修改",
    "remove": "移除",
    "yes": "是",
    "no": "否",
    "ok": "確定",
    "index.title": "國際十字路會呈獻",
    "index.donate_goods": "捐贈剩餘物資，援助需要人士",
    "index.how": "要怎樣做？",
    "index.start": "立刻行動",
    "full_name": "{{lastName}} {{firstName}}",
    "select_district": "選擇地區",
    "offer_not_found": "找不到閣下搜索的捐獻項目",
    "item_not_found": "找不到閣下搜索的物資",
    "404_error": "抱歉，您搜尋的資料並不存在",
    "403_error": "權限不足，無法執行這項行動。",
    "not_found": "不好意思，頁面失效！",
    "okay": "確定",
    "cancel": "取消",
    "save": "儲存",
    "unexpected_error": "出錯",
    "all": "全部",
    "thank_you": "謝謝",
    "go_to_top": "回到頁面頂端",
    "loading_timeout_error": "讀取時限已過",
    "items_text": "物資",
    "version": "版本",
    "crossroads_foundation": "十字路會",
    "app_review": {
      "title": "給我們評分吧!",
      "message": "不需一分鐘便能幫助推廣我們的應用程式。感謝您的支持 :)",
      "cancel_button_label": "不用了, 謝謝",
      "later_button_label": "稍後再提醒我",
      "rate_button_label": "好的",
      "yes_button_label": "是!",
      "no_button_label": "Not now",
      "app_rate_prompt_title": "感謝你的慷慨捐贈！請為好人好市應用程式評分。",
      "feedback_prompt_title": "請分享閣下的意見。"
    },
    "menu": {
      "terms": "使用條款",
      "policy": "私隱政策",
      "version_short": "版本"
    },
    "day": {
      "today": "今天",
      "morning": "早上",
      "afternoon": "下午"
    },
    "time_ago": {
      "right_now": "現正進行",
      "1m": "一分鐘",
      "m": "分鐘",
      "h": "小時",
      "d": "天",
      "mth": "個月",
      "mths": "月",
      "y": "年"
    },
    "application": {
      "home": "主頁",
      "all_offers": "所有捐獻項目",
      "login": "登入",
      "logout": "登出",
      "register": "註冊",
      "tour": "導覽"
    },
    "authenticate": {
      "input_code": "輸入代碼"
    },
    "districts": {
      "all": "全部"
    },
    "contact": {
      "title": "聯絡資料",
      "confirm_location": "輸入聯絡人資料以確認地址",
      "name": "姓名",
      "phone": "電話",
      "address": "收集地址（香港）",
      "street": "街道名稱",
      "building": "大廈名稱",
      "flat": "單位",
      "done": "完成"
    },
    "_resend": {
      "try_again": "請在 {{timer}} 秒後再試",
      "please_wait": "請等候 {{timer}} 秒以重新索取驗證碼",
      "resend": "重新發送"
    },
    "_verification_pin": {
      "input_code": "請輸入短訊中四位數字代碼：",
      "auth_error": "不好意思！請輸入正確的代碼"
    },
    "login": {
      "hk_only": "電話號碼為（只限香港）#",
      "login": "登入",
      "smscode": "取得四位數字短訊代碼"
    },
    "messages": {
      "unread": "({{unreadCount}}) 條未讀信息",
      "owner": "你",
      "today_with_time": "今天, {{time}}",
      "chat_note": "與審查員討論本物資狀況",
      "draft_chat_note": "發送捐獻項目後，可與審查員討論。"
    },
    "notifications": {
      "view": "查看"
    },
    "delivery": {
      "select_district": "選擇地區",
      "thank_offer": {
        "staff_will_contact": "我們的職員會於下星期五致電 {{mobile}} 給 {{name}} 以確認"
      },
      "book_timeslot": {
        "hint": "請選擇日期和時間。最快時間已在下方列出。",
        "select_time": "選擇時間"
      },
      "cancel": {
        "cancel_title": "取消預約",
        "cancel_transport": "取消運輸選項",
        "modify_title": "修改預約",
        "no_ggv_cancel": "我們無法取消已確認的GoGoVan預約，請見諒。",
        "no_ggv_modify": "我們無法修改已確認的GoGoVan預約，請見諒。",
        "cancel_ggv": "請取消GoGoVan預約：",
        "call_driver": "聯絡司機",
        "call_ggv": "聯絡GoGoVan",
        "back": "回到上一頁",
        "cancel_booking": "取消預約",
        "confirm_ggv_cancel": "當GoGoVan確認取消，閣下就能進行取消預約手續",
        "driver_mobile": "司機電話號碼",
        "ggv_office": "GoGoVan辦公室"
      }
    },
    "delivery_details": {
      "name": "聯絡人姓名",
      "phone": "聯絡人電話",
      "address": "地址",
      "district": "地區",
      "driver": "司機",
      "driver_contact": "司機聯絡資料",
      "vehicle_reg": "車牌號碼",
      "fee": "基本收費",
      "booked": "已確認預約",
      "driver_completed": "司機已確認預約",
      "waiting": "正在等候司機確認",
      "display_driver_details": "司機確認預約後，閣下即能查閱司機及車輛的資料。",
      "ensure_time": "請確保在下午4時或之前送抵本會。我們的義工會代為落貨。",
      "time": "預約時間",
      "id": "預約帳號",
      "driver_screen": "司機介面",
      "modify": "修改",
      "cancel_booking": "取消預約",
      "accepted_items": "已接受物資，正在等候運送",
      "driver_picked_up_items": "GoGoVan司機已接收物資",
      "charity_sale": "閣下同意我們義賣捐贈物資嗎？",
      "crossroads_phone": "十字路會電話",
      "donation_reference": "捐贈參考編號"
    },
    "items": {
      "add_item": {
        "condition": "物資狀況如何？",
        "edit_image": "修改圖片",
        "save": "儲存資料",
        "cancel": "取消",
        "description_placeholder": "描述物資，包括大小及數量等。如要新增或修改此物資相片，請點擊圖像。"
      }
    },
    "item_log": {
      "added": "{{name}} 新增物資",
      "updated": "{{name}} 修改物資",
      "accepted": "{{name}} 接受物資",
      "rejected": "{{name}} 拒絕物資",
      "submitted": "{{name}} 捐出物資",
      "received": "{{name}} 已收到物資",
      "missing": "{{name}} 將物資狀況改為'失蹤'"
    },
    "offer_log": {
      "donor_called": "{{name}} 曾經來電",
      "call_accepted": "{{name}} 接通電話"
    },
    "edit_images": {
      "upload": "選擇圖片",
      "camera": "拍照",
      "cancel": "取消",
      "add_photo": "新增圖片",
      "delete_confirm": "確定要刪除圖片嗎？",
      "cant_delete_last_image": "最少上傳一張圖片",
      "donating_what": "閣下捐贈的物資類別？",
      "take_photos": "拍照",
      "fullscreen_tooltip": "全螢幕",
      "favourite_tooltip": "設定為封面圖片",
      "delete_tooltip": "刪除圖片",
      "image_uploading": "圖片上載中",
      "remove_image": "移除圖片",
      "cancel_item": "取消項目",
      "cannot_provide_photo": "未能提供照片",
      "last_image_with_item": "此為這項物資的唯一圖片",
      "cancelling_item_will_cancel_offer": "刪除最後一項物資，即會刪除此捐獻及已預約的遞送服務。"
    },
    "plan_delivery": {
      "title": "安排遞送服務",
      "fastest": {
        "include_coupon_discount": "加入 ${{cost}} 優惠折扣。",
        "promo_discount": "優惠折扣",
        "title": "最快速",
        "info": "<br/>方便快捷，推介使用！",
        "cost": "費用由港元${{cost}}起",
        "discount_cost": "由 ${{cost}} 起",
        "booking_button": "預約時間",
        "learn_more": "了解詳情",
        "gogovan_charges": "預約GoGoVan"
      },
      "alternate": {
        "title": "其他選項",
        "info": " <br/>我們的團隊就能親身到您的社區，收集物資，必須等候最少十四日",
        "cost": "費用由港元 ${{cost}}起",
        "booking_button": "預約",
        "collection_charges": "費用"
      },
      "crossroads": {
        "title": "親身運送",
        "info": "於辦公時間，<br/>親身將物資送到我們位於屯門的辦公室",
        "free": "費用全免",
        "booking_button": "了解詳情及預約時間"
      }
    },
    "collection_charges": {
      "info": '十字路會和社會企業合作，組成物資收集服務，協助有需要人士重投勞動市場。\n\n為了支付工資及運輸費用（包括購買車輛、維修、保險等），我們很需要企業的贊助。同時，我們亦希望捐贈人士能分擔各項開支（燃油、停泊、拖車、保養等）。視乎物資所需的空間大小，一天的運輸開支平均為600元。因此閣下每次捐贈，我們均要求75元至600元不等的補助費。\n\n我們的收集服務非常受歡迎，選擇以收集服務遞送物資的話，或需等候數星期。為了節省閣下寶貴的時間，請盡量自行安排車輛或親自運送。\n\n閣下的贊助讓我們能夠擴充服務，提升質素，幫助更多的人！假如閣下的企業願意贊助運輸費，我們將於貨車輛上展示閣下的商標，展現貴企業對社會的貢獻！請即電郵至 {{email}} ，查詢贊助的詳情吧！'
    },
    "gogovan_charges": {
      "info": 'GoGoVan與好人好市合作，租用貨車，運送物資更方便！\n\n現在，我們的義工會根據閣下居住的地區和需要，決定貨車種類及大小，預先給予報價。\n\nGoGoVan的報價並不包括搬運費、隧道費及其他額外收費。在確認預約前，請先與司機商討收費。\n\n搬運費：<br/>根據過往經驗，為確保安全，搬運大型物件一般需要兩位工人協助，每位工人收費大約為每小時$100元。\n\n獨立營運的司機會使用GoGoVan運送物資，但他們並非受僱於GoGoVan。搬運物資時，不同的司機或會提供不同程度的協助，但一般而言，司機不會協助裝拆物資及將分散的部件裝箱。為使運送過程更快更方便，請預先包裹物資，或與司機商討，立刻報價。\n\n同時，如閣下居住的地點增加搬運的難度（例如有樓梯、泊車，行人道或其他障礙物），請清楚相告，讓司機按情況收費。要是地點較難到達，部分司機或不會接受此工作，請見諒。'
    },
    "gogovan": {
      "porterage": {
        "title": "搬運費用",
        "driver": "須與司機商討",
        "price_factors": "價格的釐定基於各種因素，包括",
        "item_count_size": "物資數量和大小",
        "obstacles": "路程有沒有樓梯或障礙物",
        "park_van": "停泊車輛是否無須看管",
        "help": "您及您的朋友是否能幫忙",
        "unwilling_driver": "要是捐贈大型傢俱以及/或搬運有困難，司機可能不願意協助搬運，或須收取較高昂費用。",
        "thanks": "確定，謝謝！"
      },
      "confirm_van": {
        "crossroads": "十字路會（屯門）",
        "other-msg": "並不包括隧道費及泊車費等費用",
        "title": "確定車輛",
        "base_fee": "基本費用",
        "porterage": "搬運",
        "speak_english": "通曉英語",
        "details": "詳情",
        "other": "其他",
        "confirm_with_driver": "在搬運前，請先與司機商討總價格",
        "name": "您的姓名",
        "phone": "電話",
        "book_delivery": "確認及預約",
        "negotiate": "與司機商討",
        "no_porterage": "不需要",
        "goods_6ft": "貨物>6尺",
        "trolley": "手推車",
        "estimate": "預計",
        "confirm_total_price": "預計價格並不包括隧道或天橋費、泊車以及其他雜費。請在上貨前與司機確認最終價錢。",
        "ggv_link": "請到GoGovan網站查閱更多有關費用詳情。"
      },
      "book_van": {
        "title": "預約貨車",
        "location": "地點",
        "select_day": "日期及時間",
        "crossroads_time": "只限十字路會辦公時間",
        "requirements": "額外要求",
        "speak_english": "需以英語溝通 + $5",
        "van_trolly_cost": "需借用手推車（每輛10元）",
        "truck_trolly_cost": "需借用手推車（每輛20元）",
        "porterage": "需協助搬運（需與司機商量）",
        "longer_goods": "貨物超過6尺長（183厘米）",
        "longer_goods_6ft": "超過6尺長 +$10",
        "longer_goods_6ft_tall": "超過6尺長和2尺高（60厘米） +$20",
        "extra_time_charge": "額外時間（已包括15分鐘搬運時間，超過15分鐘可能需要額外收費）",
        "get_quote": "報價",
        "date": "日期",
        "time": "時間",
        "am": "上午",
        "pm": "下午",
        "vehicle": "車輛種類"
      }
    },
    "drop-off": {
      "select_schedule": "選擇時間",
      "expect_delivery": "物資大約何時到達？",
      "open_new_tab": "將在新視窗打開",
      "book_schedule": "預約時間",
      "arrive_on_time": "為確保能收到閣下捐贈的物資，請在辦公時間光臨。",
      "volunteer": "我們的義工團隊將協助搬運。",
      "see_map": "詳見地圖",
      "delivery_to": "請送到：",
      "crossroads_address": "<br/>新界屯門青山公路2號<br/> 十字路會32號大樓",
      "arrive": "請在辦公時間光臨。",
      "message_us": "如有任何疑問，請通過好人好市應用程式向我們查詢。",
      "thanks": "閣下安排遞送物資，我們衷心感激。"
    },
    "crossroads_truck": {
      "book_time": "預約時間",
      "show_more": "更多"
    },
    "offer": {
      "disable": "關閉",
      "closed_by": "由 {{lastName}} {{firstName}} 關閉",
      "cancelled_by": "由 {{lastName}} {{firstName}} 取消",
      "received_by": "由 {{lastName}} {{firstName}} 接收"
    },
    "upload-images": {
      "angles": "圖片包含物資的所有角度嗎？",
      "favourite": "我的最愛",
      "add_another": "加入另一張圖片"
    },
    "upload-image": {
      "upload_error": "圖片上傳時出錯，請稍後再試一次。"
    },
    "ggv_order": {
      "message": "您到達時，我們的職員會核對並確認下列資料",
      "delivery_from": "寄出地點",
      "delivery_to": "運送至：屯門",
      "crossroads_foundation": "十字路會",
      "crossroads_address1": "屯門青山公路2號",
      "crossroads_address2": "黃金海岸1期對面，並於32號大樓落貨",
      "map": "地圖",
      "goodcity_job_summary": "好人好市工作摘要"
    },
    "pics": {
      "title": "個人資料",
      "confidentiality": '十字路會全力保護在活動中，包括從好人好市的網站www.goodcity.hk（「我們」、「我們的」、「十字路會」」及「好人好市.HK」）上收集的個人資料，確保不會外洩。',
      "ask_to_access": "本個人資料收集聲明（PICS）列出我們對處理個人資料的政策及使用情況，包括在您註冊以通過「好人好市.HK」接收物資時，我們要求您提供的個人資料。您可以閱讀我們完整的私隱政策。",
      "here": "這裏",
      "change_privacy_policy": "我們保證依照香港個人資料(私隱)條例列明的要求，我們可能因時更改此PICS或我們的私隱政策，並會於此處更新及張貼詳情。繼續使用「好人好市.HK」即表示您同意這些變更。",
      "child": "假如您為18歲以下青少年，請徵求父母或監護人的同意，方可使用「好人好市.HK」。",
      "commitement_to_you": "我們的承諾",
      "use_purposely": "我們只會將您提供的個人資料用作收集或其他直接相關的用途；",
      "will_not_sell_dara": "我們不會在您不知情（或根據要求，不同意）的情況下，販賣或將您的個人資料轉讓給任何其他機構；",
      "unsubscribe": "您隨時都可以取消訂閱我們的通訊；",
      "questions": "我們非常重視我們的法律責任，假如您有任何疑問或憂慮，請隨時聯絡我們。",
      "personal_data_collected": "個人資料收集",
      "provide_data_to_use_services": "當你於「好人好市.HK」註冊時，我們或會要求您提供以下各項個人資料。請注意，部分資料或為必須提供的，我們會於收集時清楚註明。缺少這些資料，我們可能無法向您提供我們的物資或服務：",
      "contact_details": "您的姓名、地址、電郵及傳真號碼；",
      "gender_and_dob": "您的性別及出生日期；",
      "financial_information": "財政資料，例如信用卡資料（假如您向我們付款）及銀行賬戶資料（假如我們向您轉賬金額）；",
      "personal_identifier": "身份證明文件資料，如護照及其他官方文件號碼。除非有特殊情況，否則我們不會收集或保存您的香港身份證號碼；",
      "responses_to_feedback": "您於問卷上的回答或建議。",
      "purpose_and_use": "目的及使用情況",
      "will_use_data_for_following": "我們或會使用您提供的個人資料作以下用途，或其他直接相關的用途：",
      "new_or_any_invitation": "在您要求或清楚表明的同意下，用作報道我們的活動、計劃、產品、研究及未來項目的新聞，更新或邀請；",
      "respond_to_your_questions": "回應您的查詢或提議；",
      "to_process_feedback": "處理建議或跟進您的查詢；",
      "requested_by_govt_or_legal_claim": "根據法律及條例授權或受政府要求提供，或用以確保或行使我們的合法權利或追究法律責任；",
      "deliver_items_to_you": "向您運送物資或處理您的要求；",
      "improve_services": "改善我們的服務、活動、產品及內容，以及用作數據及其他分析，以改善我們的效率；",
      "prevent_crime": "預防或檢察出罪案或可能的犯罪活動。",
      "third_parties": "第三方",
      "third_party_organisations": "我們或會保留讓其他組織及個別人士代表我們履行職責，例如協助我們物流安排的合作夥伴GoGoVan。這些第三方或會得到准許取得您的個人資料，以履行其職責，但我們並不會授權他們將這些資料用作其他用途。這些第三方或是位於香港的機構。我們的政策在於選取合乎我們需要，並擁有良好的技術及組織能力，信譽可靠的服務提供者。他們必須擁有保障私隱的良好記錄。",
      "social_welfare_dept": "假如你得到其他機構轉介（例如社會福利署或其他機構），我們或須於他們討論，並根據情況於過程中披露或轉移您的個人資料。",
      "crossroads_network": "十字路會網絡",
      "use_data_in_crossroads_network": '十字路會於全球運作。我們於香港及英國皆設有辦公室，我們將之稱為「十字路會網絡」。我們或會根據前述PICS的目的，於十字路會網絡內披露、分享及轉移您的個人資料。我們要求十字路會網絡的人員皆遵守於此PICS及我們的私隱政策中列明的數據保安及保密的程序。',
      "correction_of_data": "存取及更正個人資料",
      "right_to_review_and_correct": "您有權利閱覽及更改您所提供的個人資料。假如閱覽或更新的過程中產生任何費用，我們或會向您收取合理的費用。假如您希望閱覽或更新個人資料，或有任何關於此PICS或我們的私隱政策的疑問，歡迎隨時聯絡我們，電郵為：privacy@crossroads.org.hk",
      "last_updated": "本聲明最後更新日期為2014年8月。"
    },
    "terms": {
      "title": "使用條款及細則",
      "info": "好人好市.hk為十字路會聯網的一部分。使用好人好市.hk網頁http://www.goodcity.hk (\"好人好市網站\")，即表示閣下同意閣下與十字路會及其旗下所屬部門 (\"十字路會\", \"我們\" and \"我們的\")之間的使用條款。",
      "agreement": {
        "title": "閣下的同意書",
        "terms_of_use": "閣下同意跟從下列使用條款使用好人好市網站。",
        "use_authority": "閣下同意僅以個人身份使用好人好市網站。假如閣下代表公司、合作夥伴、聯會、政府或其他機構使用好人好市網頁，閣下必須代表並保證已經得到貴機構之授權，並代表其遵從下列使用條款。",
        "lawful_use": "閣下同意僅為合法目的使用好人好市網站。",
        "security": "閣下同意不會採取任何可能損害好人好市網站保安之手段，導致他人無法讀取好人好市網站及其內容，並/或對之造成損害。",
        "access_attempt": "閣下同意不會企圖讀取好人好市網站上，任何不對閣下開放的內容；",
        "website_rights": "閣下同意不會以任何可能損害第三方權益的方式使用好人好市網站。"
      },
      "changes": {
        "title": "使用條款的更改",
        "info": "我們或會間中發布、修正或增加有關閣下使用好人好市網站的特定指引、政策及條款，而這些指引、政策及條款為使用條款的一部份。請細閱好人好市網站上的使用條款，並間中重閱。使用條款經過修正後繼續使用好人好市網站，即表示閣下同意經改正後的條款。",
        "contact_us": "如閣下對好人好市網站上的使用條款有任何疑問，請與我們聯絡"
      },
      "account": {
        "title": "帳戶資料",
        "info": "閣下的帳戶名稱為我們的資產。不論任何原因或肇事者，一旦閣下的帳戶終止使用或遭暫停，我們能夠收回該帳戶名稱，並重新使用。閣下有責任保障登入帳戶及好人好市網站的密碼。假如閣下認為密碼或帳戶遭入侵，必須儘快電郵至privacy@crossroads.org.hk通知我們。"
      },
      "privacy": {
        "title": "私隱政策",
        "info": "我們十分重視閣下的私隱。當閣下登記，經由好人好市接收物資時，我們或回查詢閣下的個人資料，我們已經準備收集個人資料聲名（PICS），詳列我們有關個人資料的私隱政策及執行細節。",
        "pics": "閣下可以隨時翻閱PICS",
        "privacy_link": "十字路會完整的私隱政策亦可供查詢",
        "use": "這些政策為好人好市網站使用條款的一部分。",
        "here": "這裏"
      },
      "intellectual": {
        "title": "知識產權",
        "info": "好人好市網站包含的一切知識產權（包括將來一切的資料更新、系統更新及新版本）及任何相聯或相關的程式、系統及服務將一直屬於我們及我們的發牌人。除了在得到我們明確授權外，閣下並無權利使用我們的知識產權。特別指出，如事前未得到我們的書面同意，閣下並無權使用我們的商標或產品名稱，標誌，網域名稱或其他明顯的品牌細節。",
        "grant": "僅在合乎使用條款的情況下，我們給予閣下使用好人好市網站的使用權利，該使用權利供閣下個人，但不限於閣下一人使用，授權不得二次授權，不得轉讓，並不需專利費用，我們保留撤回的權利。",
        "unauthorised_access": "除了在現行法例導致我們或不會禁止閣下，或閣下事前得到書面同意的情況下，閣下不能從好人好市網站或任何相聯或相關程式、系統或服務中複製、更改、進行逆向工程或提取任何資料，程式源代碼或基礎HTML。在使用好人好市網站時，閣下不能同時使用任何可能導致或嘗試引致上列或其他令好人好市網站以未經授權的方式運行的程式或其他技術。"
      },
      "content": {
        "title": "閣下的內容",
        "info": "當閣下使用好人好市網站時提交、上載、傳輸或展示任何照片、數據、資料、媒介或其他內容（閣下的內容），即表示閣下明白並同意：",
        "responsible": "閣下將繼續擁有閣下的內容，並對其負責。",
        "no_sell": "我們不會將閣下的內容銷售給第三方。",
        "privacy_policy": "我們將跟隨我們的私隱政策使用閣下的內容。",
        "right_to_use": "閣下給予我們及與我們相關的公司使用閣下的內容的權利（我們不須向閣下支付任何費用），用以提供我們的服務，並進行宣傳、發展和改善我們的服務的目的，包括我們於將來新增的服務。",
        "share": "我們或會向與我們合作的第三方分享閣下的內容，以提供、宣傳、發展及改善我們的服務，但該第三方（非與我們相關的公司）將不會以私人目的單獨使用閣下的內容（意指任何與我們的服務無關的目的），並且",
        "suggestions": "閣下提供任何有關好人好事網站的意見或建議均屬自願性質，我們將能夠免費使用，而不須向閣下進行任何義務。"
      },
      "warranty": {
        "title": "擔保及免責聲明",
        "info": "我們保證為好人好市網站及其相關服務提供合理保養及技術。然而，除此擔保外，對於我們的服務、軟件或任何已提交、傳輸或展示的內容，我們並不表示或擔保以及承諾會完全安全或不出錯、或好人好市會和閣下使用的裝置或任何安裝在裝置上的軟件兼容，這包括我們服務中，任何在好人好市網站或其他任何軟件、內容或資料中的顯示、擔保或承諾。好人好市網站僅提供現有、可用的版本。"
      },
      "liability": {
        "title": "責任",
        "info": "無論因為任何情況，好人好市網站及與其相關的公司的整體責任僅限至總計100美元。",
        "exclude": "使用條款中沒有任何情況可免除任何由疏忽導致的死亡或人身傷害所造成的一切損失，欺詐誤導或任何其他責任均不能受限於或免除法律責任。",
        "indemnify_us": "假如閣下違反任何使用條款，即閣下同意對我們作出賠償，並不能追究我們因此而造成的任何責任或損失。"
      },
      "policy_to_children": {
        "title": "對兒童政策",
        "info": "好人好市網站的使用對象並不包括兒童。然而，我們並無法完全斷定使用者的年齡。假如閣下的年齡為18歲或以下，請勿使用好人好市網站。"
      },
      "general": {
        "title": "一般",
        "info": "這些好人好市網站使用條款完全是閣下和我們之間有關好人好市網站的合約。閣下同意不會就任何並未在這些使用條款中明確列明的條例向我們追究。如使用條款的實施有所延誤，不能將之視為放棄規定下的任何權利。根據其原意，在使用條款遭終止或過期前，使用條款中的任何權利及義務均有效並應得以保存，包括但不限於任何對相關方面責任的義務。",
        "hk_law": "使用條款及與其相關或衍生的任何紛爭或索償均受香港特別行政區法律的監管。使用條款衍生出的或相關的任何法律程序皆由，並不限於香港特別行政區法院處理。",
        "language": "如使用條款的英文版本與其他語言版本有出入，皆以英文版本為凖。"
      },
      "contact": {
        "title": "聯絡我們",
        "info": "假如閣下對好人好市網站或這些使用條款有任何問題或疑問，請聯絡我們"
      }
    }
  };

  exports["default"] = I18nTranslationsZhTw;
});