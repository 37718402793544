define('ember-cli-html5-validation/components/validatable-form', ['exports', 'ember', 'ember-cli-html5-validation/templates/components/validatable-form'], function (exports, _ember, _emberCliHtml5ValidationTemplatesComponentsValidatableForm) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCliHtml5ValidationTemplatesComponentsValidatableForm['default'],

    /**
     * @type {string}
     */
    tagName: 'form',

    /**
     * @type {Array}
     */
    attributeBindings: ['novalidate'],

    /**
     * Prevent the built-in browser navigation error messages to pop up
     *
     * @type {string}
     */
    novalidate: 'novalidate',

    /**
     * @type {Array}
     */
    classNames: ['form'],

    /**
     * List of all error messages, indexed by the input ID
     *
     * @type {Object}
     */
    errors: {},

    /**
     * @type {boolean}
     */
    isSubmitting: false,

    /**
     * Scroll to the first input field that does not pass the validation
     *
     * @returns {void}
     */
    scrollToFirstError: function scrollToFirstError() {
      var form = this.get('element');

      // We get the first element that fails, and scroll to it
      for (var i = 0; i !== form.elements.length; ++i) {
        if (!form.elements[i].validity.valid) {
          _ember['default'].$('html, body').animate({
            scrollTop: _ember['default'].$(form.elements[i]).offset().top - 40
          }, 200);

          break;
        }
      }
    },

    actions: {
      /**
       * @param {string} inputName
       * @param {string} errorMessage
       */
      updateErrorMessage: function updateErrorMessage(inputName, errorMessage) {
        if (_ember['default'].isNone(errorMessage)) {
          delete this.get('errors')[inputName];
        } else {
          this.get('errors')[inputName] = errorMessage;
        }

        this.rerender();
      },

      /**
       * Handle the submit event only if the form is valid
       */
      submit: function submit(eventName) {
        var _this = this;

        if (!this.get('element').checkValidity()) {
          this.scrollToFirstError();
          return false;
        }

        this.set('isSubmitting', true);

        new _ember['default'].RSVP.Promise(function (resolve) {
          resolve(_this.get(eventName || 'onSubmit')());
        }).then(function () {
          _this.set('isSubmitting', false);
        });

        return false;
      }
    }
  });
});