define('smoke-and-mirrors/components/vertical-collection', ['exports', 'ember', 'smoke-and-mirrors/mixins/occlusion-collection', 'smoke-and-mirrors/templates/components/vertical-collection', 'smoke-and-mirrors/debug/vertical-collection-debugging'], function (exports, _ember, _smokeAndMirrorsMixinsOcclusionCollection, _smokeAndMirrorsTemplatesComponentsVerticalCollection, _smokeAndMirrorsDebugVerticalCollectionDebugging) {
  var Component = _ember['default'].Component;

  var VerticalCollection = Component.extend(_smokeAndMirrorsMixinsOcclusionCollection['default'], {
    /*
     * Defaults to `vertical-collection`.
     *
     * If itemTagName is blank or null, the `vertical-collection` will [tag match](../addon/utils/get-tag-descendant.js)
     * with the `vertical-item`.
     */
    tagName: 'vertical-collection',
    layout: _smokeAndMirrorsTemplatesComponentsVerticalCollection['default'],

    // –––––––––––––– Required Settings

    /*
     * This height is used to give the `vertical-item`s height prior to
     * it's content being rendered.
     *
     * This height is replaced with the actual rendered height once content
     * is rendered for the first time.
     */
    defaultHeight: 75,
    alwaysUseDefaultHeight: false,

    /*
     * Cached value used once default height is
     * calculated firmly
     */
    _defaultHeight: null,

    // –––––––––––––– Optional Settings

    /*
     * Set this if you need to dynamically change the height of the container
     * (useful for viewport resizing on mobile apps when the keyboard is open).
     *
     * Changes to this property's value will trigger new viewport boundary
     * calculations.  This works for height or width changes, it's value is
     * never actually used.
     */
    containerSize: null

  });

  _ember['default'].runInDebug(function () {
    VerticalCollection.reopen(_smokeAndMirrorsDebugVerticalCollectionDebugging['default']);
  });

  exports['default'] = VerticalCollection;
});