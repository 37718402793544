define('smoke-and-mirrors/components/horizontal-collection', ['exports', 'ember', 'smoke-and-mirrors/mixins/occlusion-collection', 'smoke-and-mirrors/templates/components/horizontal-collection'], function (exports, _ember, _smokeAndMirrorsMixinsOcclusionCollection, _smokeAndMirrorsTemplatesComponentsHorizontalCollection) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend(_smokeAndMirrorsMixinsOcclusionCollection['default'], {
    layout: _smokeAndMirrorsTemplatesComponentsHorizontalCollection['default'],

    defaultWidth: 200,
    _defaultWidth: null,
    alwaysUseDefaultWidth: false,

    /*
     * Defaults to `div`.
     *
     * If itemTagName is blank or null, the `horizontal-collection` will [tag match](../addon/utils/get-tag-descendant.js)
     * with the `horizontal-item`.
     */
    tagName: 'horizontal-collection',

    /*
     * Used if you want to explicitly set the tagName of `horizontal-item`s
     */
    itemTagName: ''

  });
});