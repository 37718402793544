define('smoke-and-mirrors/components/vertical-item', ['exports', 'ember', 'smoke-and-mirrors/templates/components/vertical-item', 'ember-getowner-polyfill'], function (exports, _ember, _smokeAndMirrorsTemplatesComponentsVerticalItem, _emberGetownerPolyfill) {
  var Component = _ember['default'].Component;

  /*
   A vertical-item is one that intelligently removes
   its content when scrolled off the screen vertically.
  
   @class vertical-item
   @extends Ember.Component
   @namespace Ember
   **/
  exports['default'] = Component.extend({
    layout: _smokeAndMirrorsTemplatesComponentsVerticalItem['default'],
    tagName: 'vertical-item',
    itemTagName: 'vertical-item',

    heightProperty: 'minHeight',
    alwaysUseDefaultHeight: false,

    classNames: ['vertical-item'],

    next: function next() {
      var element = this.element.nextElementSibling;

      return element ? this.registry[element.id] : null;
    },

    prev: function prev() {
      var element = this.element.previousElementSibling;

      return element ? this.registry[element.id] : null;
    },

    contentInserted: false,
    _contentInserted: false,

    /*
     * Reveal the Element
     *
     */
    show: function show() {
      if (this._contentInserted) {
        return;
      }
      var heightProp = this.heightProperty;

      this._contentInserted = true;
      this.set('contentInserted', true);
      if (!this.alwaysUseDefaultHeight) {
        this.element.style[heightProp] = null;
      }
      this.updateHeight();
    },

    /*
     * Destroy the View/Element
     *
     * Unlike the other methods, this method
     * can be called from any state. It is still not valid
     * to transition to it directly, but willDestroy uses it
     * to teardown the instance.
     */
    cull: function cull() {
      if (!this._contentInserted) {
        return;
      }
      var heightProp = this.heightProperty;

      this.updateHeight();
      if (!this.alwaysUseDefaultHeight && this.element) {
        this.element.style[heightProp] = this.satellite.geography.height + 'px';
      }

      this._contentInserted = false;
      this.set('contentInserted', false);
    },

    _hasRealHeight: false,
    updateHeight: function updateHeight() {
      var needsRealHeight = !this.alwaysUseDefaultHeight;

      if (needsRealHeight) {
        this.satellite.resize();
      }
    },

    defaultHeight: 75,
    index: null,
    content: null,

    radar: null,
    satellite: null,
    registerSatellite: function registerSatellite(satellite) {
      this.satellite = satellite;
    },
    unregisterSatellite: function unregisterSatellite() {
      this.satellite = null;
    },

    _height: 0,

    didInsertElement: function didInsertElement() {
      this._super();
      this.radar.register(this);
    },

    willInsertElement: function willInsertElement() {
      this._super();
      var _height = this.get('_height');
      var heightProp = this.get('heightProperty');
      var defaultHeight = this.get('defaultHeight');

      if (typeof defaultHeight === 'number') {
        defaultHeight = defaultHeight + 'px';
      }

      this.element.style[heightProp] = _height ? _height + 'px' : defaultHeight;
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.set('contentInserted', false);
      this._contentInserted = false;

      if (this.radar) {
        this.radar.unregister(this);
      }
      this.satellite = null;
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.unregister(this);

      if (this.radar) {
        this.radar.unregister(this);
      }
      this.satellite = null;
      this.registry = null;
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.registry = (0, _emberGetownerPolyfill['default'])(this).lookup('-view-registry:main') || _ember['default'].View.views;
      var tag = this.get('itemTagName');

      this.set('tagName', tag);
      tag = tag.toLowerCase();

      var isTableChild = tag === 'tr' || tag === 'td' || tag === 'th';

      // table children don't respect min-height :'(
      this.heightProperty = isTableChild || this.alwaysUseDefaultHeight ? 'height' : 'minHeight';
      this.register(this);
    }

  });
});