define('smoke-and-mirrors/utils/element/get-height', ['exports'], function (exports) {
  exports['default'] = getHeight;

  function getHeight(dims, withMargins) {
    var height = undefined;

    switch (dims.boxSizing) {
      case 'border-box':
        height = dims.height + dims.borderTopWidth + dims.borderBottomWidth + dims.paddingTop + dims.paddingBottom;
        break;
      case 'content-box':
        height = dims.height;
        break;
      default:
        height = dims.height;
        break;
    }
    if (withMargins) {
      height += dims.marginTop + dims.marginBottom;
    }
    return height;
  }
});